
import { formatExploreResults, explore_property_pins } from "../MapBoxConfig"
import { onClickPropertyPin, easeToDefault } from "~/helpers/map-helpers.js"
import SymbolLayer from "./basicLayers/SymbolLayer.vue"

export default {
  components: {
    SymbolLayer
  },

  props: {
    results: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      explore_property_pins
    }
  },

  mounted() {
    if (!this.results) {
      this.easeToDefault()
    }
  },

  methods: {
    onClickPropertyPin,
    formatExploreResults,
    easeToDefault
  }
}
