// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flexContainer_8dEA6{display:flex;flex-direction:column;height:100%}.controls_KjaYh{display:flex;flex-direction:row-reverse;justify-content:space-between;width:100%}.pullRight_zTmXI{align-items:flex-end;display:flex;flex-direction:column;position:absolute;right:1rem;top:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexContainer": "flexContainer_8dEA6",
	"controls": "controls_KjaYh",
	"pullRight": "pullRight_zTmXI"
};
module.exports = ___CSS_LOADER_EXPORT___;
