var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    "class": _vm.$style.flexContainer
  }, [_c('Composition', {
    attrs: {
      "explodeHeight": _vm.resultsDisplayMode === 'table',
      "loadingColor": "--color-black-50"
    },
    scopedSlots: _vm._u([{
      key: "layouts",
      fn: function fn() {
        return [_c('CompositionLayoutPagedResultsSplit', {
          attrs: {
            "loading": _vm.loading,
            "loadingColor": "--color-black-50",
            "resultsTotal": _vm.totalResults || 0,
            "resultsPerPage": _vm.resultsPerPage,
            "resultsCurrentPage": _vm.resultsCurrentPage,
            "labelPagePrevious": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_PREVIOUS'),
            "labelPageNext": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_NEXT'),
            "labelPagePosition": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_POSITION')
          },
          on: {
            "page-next": function pageNext($event) {
              return _vm.pageNext();
            },
            "page-previous": function pagePrevious($event) {
              return _vm.pagePrevious();
            },
            "page-change": _vm.pageChange
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('Copy', {
                attrs: {
                  "type": "header",
                  "size": "large",
                  "color": "--color-black-900"
                }
              }, [_vm._v("\n            " + _vm._s(_vm.totalResults ? _vm.totalResults : 0) + "\n            " + _vm._s(_vm.$t("EXPLORE_RESULTS_TITLE_LOWER")) + "\n          ")]), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "small"
                }
              }), _vm._v(" "), _c('div', {
                "class": _vm.$style.pullRight
              }, [_c('PushButton', {
                attrs: {
                  "size": "small",
                  "color": "--color-transparent",
                  "underline": ""
                },
                on: {
                  "click": _vm.saveAllToList
                },
                scopedSlots: _vm._u([{
                  key: "iconBefore",
                  fn: function fn() {
                    return [_c('Icon', {
                      attrs: {
                        "id": "bookmark",
                        "size": "large",
                        "color": "--color-purple-900"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "copy",
                  fn: function fn() {
                    return [_c('Copy', {
                      attrs: {
                        "type": "body",
                        "size": "small",
                        "color": "--color-purple-900"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm._f("lowercase")(_vm.$t("LIST_SAVE_ALL"))))])])];
                  },
                  proxy: true
                }])
              })], 1)];
            },
            proxy: true
          }, {
            key: "chips",
            fn: function fn() {
              return [_c('ChipContainerRow', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_ACTIVE_FILTERS")) + "\n              ")])];
                  },
                  proxy: true
                }])
              }, [_vm._v(" "), _vm._l(_vm.filters, function (filter, index) {
                return _c('Chip', {
                  key: "filter".concat(index),
                  attrs: {
                    "colorForeground": "--color-white",
                    "colorBackground": "--color-purple-500"
                  },
                  scopedSlots: _vm._u([{
                    key: "copy",
                    fn: function fn() {
                      return [_c('Copy', {
                        attrs: {
                          "type": "label",
                          "size": "small",
                          "color": "--color-white"
                        }
                      }, [_vm._v("\n                  " + _vm._s(filter) + "\n                ")])];
                    },
                    proxy: true
                  }], null, true)
                });
              })], 2)];
            },
            proxy: true
          }, {
            key: "controls",
            fn: function fn() {
              return [_c('div', {
                "class": _vm.$style.controls
              }, [!_vm.$isMobile ? _c('ToggleButton', {
                attrs: {
                  "colorBackground": "--color-black-50",
                  "colorBackgroundActive": "--color-black-500",
                  "colorBackgroundHover": "--color-black-100",
                  "colorLabelActive": "--color-white",
                  "colorLabelInactive": "--color-black-900"
                },
                model: {
                  value: _vm.resultsDisplayMode,
                  callback: function callback($$v) {
                    _vm.resultsDisplayMode = $$v;
                  },
                  expression: "resultsDisplayMode"
                }
              }, [_c('ToggleButtonItem', {
                attrs: {
                  "value": "cards",
                  "labelText": _vm.$t('REFERENCE_PROPERTIES_LABEL_VIEW_CARDS'),
                  "iconId": "view_stream"
                }
              }), _vm._v(" "), _c('ToggleButtonItem', {
                attrs: {
                  "value": "table",
                  "labelText": _vm.$t('REFERENCE_PROPERTIES_LABEL_VIEW_TABLE'),
                  "iconId": "list"
                }
              })], 1) : _vm._e()], 1)];
            },
            proxy: true
          }, _vm.exploreProperties != null && _vm.exploreProperties.length > 0 ? {
            key: "results",
            fn: function fn() {
              return [_vm._l(_vm.exploreProperties, function (result, index) {
                return _vm.resultsDisplayMode === 'cards' ? _c('div', {
                  key: "result-cards-".concat(index),
                  "class": _vm.$style.flexContainer
                }, [_c('SearchResultCardProperty', {
                  attrs: {
                    "bfeNumber": result.bfeNumber,
                    "addressText": result.address,
                    "typeText": _vm.getPropertyType(result),
                    "keyFigures": result.keyFigures,
                    "keyFigureLabels": _vm.keyFigureLabels,
                    "owners": result.owners,
                    "administrators": result.administrators,
                    "isSavedOnList": result.isPropertyOnList
                  },
                  on: {
                    "save-to-list": _vm.saveToList,
                    "copy-to-clipboard": _vm.copyToClipboard
                  }
                }), _vm._v(" "), index < _vm.exploreProperties.length - 1 ? _c('CompositionSpacer', {
                  attrs: {
                    "direction": "vertical",
                    "size": "small"
                  }
                }) : _vm._e()], 1) : _vm._e();
              }), _vm._v(" "), _vm.resultsDisplayMode === 'table' ? _c('div', {
                "class": _vm.$style.flexContainer
              }, [_c('Composition', {
                attrs: {
                  "explodeHeight": _vm.resultsDisplayMode === 'table'
                },
                scopedSlots: _vm._u([{
                  key: "backgrounds",
                  fn: function fn() {
                    return [_c('CompositionBackgroundCard', {
                      attrs: {
                        "color": "--color-white"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "layouts",
                  fn: function fn() {
                    return [_c('SimpleTable', [_c('SimpleTableRow', {
                      attrs: {
                        "fixed": "",
                        "hoverEffectDisable": ""
                      }
                    }, [_c('SimpleTableCell', {
                      attrs: {
                        "fixed": _vm.$isMobile ? false : true
                      }
                    }, [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                        " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_TABLE_ADDRESS")) + "\n                      ")])], 1), _vm._v(" "), _c('SimpleTableCell', [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                        " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_TABLE_TYPE")) + "\n                      ")])], 1), _vm._v(" "), _vm._l(_vm.keyFigureLabels, function (label, index) {
                      return _c('SimpleTableCell', {
                        key: index
                      }, [_c('Copy', {
                        attrs: {
                          "type": "label",
                          "size": "small",
                          "color": "--color-black-900"
                        }
                      }, [_vm._v("\n                        " + _vm._s(label.translation) + "\n                      ")])], 1);
                    }), _vm._v(" "), _c('SimpleTableCell', [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                        " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_TABLE_OWNERS_AND_ADMINISTRATORS")) + "\n                      ")])], 1)], 2), _vm._v(" "), _vm._l(_vm.exploreProperties, function (result, index) {
                      return _c('SearchResultTableCellProperty', {
                        key: "result-table-".concat(index),
                        attrs: {
                          "bfeNumber": result.bfeNumber,
                          "addressText": result.address,
                          "typeText": _vm.getPropertyType(result),
                          "keyFigures": result.keyFigures,
                          "keyFigureLabels": _vm.keyFigureLabels,
                          "owners": result.owners,
                          "administrators": result.administrators,
                          "isPropertyOnList": result.isPropertyOnList
                        },
                        on: {
                          "save-to-list": _vm.saveToList,
                          "copy-to-clipboard": _vm.copyToClipboard
                        }
                      });
                    })], 2)];
                  },
                  proxy: true
                }], null, false, 3942918757)
              })], 1) : _vm._e()];
            },
            proxy: true
          } : {
            key: "results",
            fn: function fn() {
              return [[_c('EmptyState', {
                attrs: {
                  "color": "--color-black-high-emphasis",
                  "textHeader": _vm.$t('EMPTY_STATE_EXPLORE_RESULTS_TITLE'),
                  "textBody": _vm.$t('EMPTY_STATE_EXPLORE_RESULTS_SUBTITLE')
                }
              })]];
            },
            proxy: true
          }], null, true)
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('portal', {
    attrs: {
      "to": "map-layer"
    }
  }, [_c('ExploreMapLayer', {
    attrs: {
      "results": _vm.results && _vm.results.hits
    }
  })], 1), _vm._v(" "), _c('portal', {
    attrs: {
      "to": "chrome-notification-layer-1"
    }
  }, [_c('AddToListDialog', {
    attrs: {
      "visible": _vm.showAddToList,
      "properties": _vm.propertiesToSave,
      "origin": "company portfolio"
    },
    on: {
      "close": function close($event) {
        _vm.showAddToList = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };